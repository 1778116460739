<template>
    <div>
        <el-card>
            <template #header>
                <div class="card-header" style="text-align:left">
                    <el-col :span = "4" class="hidden-md-and-down">
                    <span class="card-title">套餐信息</span>
                    </el-col>
                </div>
            </template>
            <div class="content">
                <el-descriptions border :column="4"  direction="vertical">
                    <el-descriptions-item label="品类"><el-tag>{{category}}</el-tag></el-descriptions-item>
                    <el-descriptions-item label="品牌"><el-tag>{{brand}}</el-tag></el-descriptions-item>
                    <el-descriptions-item label="型号"><el-tag>{{model}}</el-tag></el-descriptions-item>
                    <el-descriptions-item label="产品"><el-tag>{{product}}</el-tag></el-descriptions-item>
                </el-descriptions>
                <el-divider></el-divider>
                <div>
                    <el-form :model="planinfo" :rules="rules" ref="planinfo" label-width="120px">
                        <el-form-item label="套餐标题" prop="title">
                            <el-input v-model="planinfo.title" style="width:300px;"></el-input>
                        </el-form-item>
                        <el-form-item label="选择租赁方式" prop="plantype">
                            <el-select v-model="planinfo.plantype" placeholder="请选择" filterable @change="ReSetTable" disabled>
                                <el-option label="租赁" value="1"></el-option>
                                <el-option label="到期赠送" value="2"></el-option>
                                <el-option label="售卖" value="3"></el-option>
                                <el-option label="定制租赁" value="4"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="售卖价(元)" prop="rprice" v-if="planinfo.plantype == 3" >
                            <el-input style="width:215px;" v-model="planinfo.rprice" @blur="CheckPrice(planinfo.rprice)"></el-input>
                        </el-form-item>
                        <el-form-item label="原价(元)" prop="price" v-if="planinfo.plantype == 3" >
                            <el-input style="width:215px;" v-model="planinfo.price" @blur="CheckPrice(planinfo.price)"></el-input>
                        </el-form-item>
                        <el-form-item label="押金(元)" prop="deposit" v-if="planinfo.plantype != 3">
                            <el-input style="width:215px;" v-model="planinfo.deposit"  @blur="CheckPrice(planinfo.deposit)"></el-input>
                        </el-form-item>
                        <el-form-item label="成本价(元)" prop="margin" v-if="planinfo.plantype != 3" >
                            <el-input style="width:215px;" v-model="planinfo.margin" @blur="CheckPrice(planinfo.margin)"></el-input>
                        </el-form-item>
                        <el-form-item label="选择租期" prop="renttype" v-if="planinfo.plantype == 1 || planinfo.plantype == 2 || planinfo.plantype == 4" required>
                            <el-radio-group v-model="planinfo.renttype" @change="ResetType">
                                <el-radio :label="1" v-if="planinfo.plantype == 1 || planinfo.plantype == 4">按天(平均价)</el-radio>
                                <el-radio :label="2" v-if="planinfo.plantype == 1 || planinfo.plantype == 4">按天(阶梯价)</el-radio>
                                <el-radio :label="3">按月(平均价)</el-radio>
                                <el-radio :label="4">按月(阶梯价)</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="选择租期范围" prop="rentrank" v-if="planinfo.plantype == 1 || planinfo.plantype == 2 || planinfo.plantype == 4">
                            <el-select v-model="planinfo.rankstart" placeholder="请选择" @change="AlterTable">
                                <el-option label="1天" value="1" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                                <el-option label="3天" value="2" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                                <el-option label="7天" value="3" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                                <el-option label="15天" value="4" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                                <el-option label="1个月" value="5"></el-option>
                                <el-option label="3个月" value="6"></el-option>
                                <el-option label="6个月" value="7"></el-option>
                                <el-option label="12个月" value="8"></el-option>
                                <el-option label="24个月" value="9"></el-option>
                                <el-option label="36个月" value="10"></el-option>
                            </el-select>
                            <span style="width:15px"> - </span>
                            <el-select v-model="planinfo.rankend" placeholder="请选择" @change="AlterTable">
                                <el-option label="1天" value="1" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                                <el-option label="3天" value="2" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                                <el-option label="7天" value="3" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                                <el-option label="15天" value="4" v-if="planinfo.renttype == 1 || planinfo.renttype == 2"></el-option>
                                <el-option label="1个月" value="5"></el-option>
                                <el-option label="3个月" value="6"></el-option>
                                <el-option label="6个月" value="7"></el-option>
                                <el-option label="12个月" value="8"></el-option>
                                <el-option label="24个月" value="9"></el-option>
                                <el-option label="36个月" value="10"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="是否可以买断" prop="buyout" required v-if="planinfo.plantype == 1 || planinfo.plantype == 2 || planinfo.plantype == 4">
                            <el-radio-group v-model="planinfo.buyout">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="2">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="签约价值(元)" prop="buyoutprice" v-if="planinfo.plantype == 1 || planinfo.plantype == 2 || planinfo.plantype == 4">
                            <el-input style="width:215px;" v-model="planinfo.buyoutprice"></el-input>
                        </el-form-item>
                        <el-form-item label="是否可以续租" prop="rerent" required v-if="planinfo.plantype == 1 || planinfo.plantype == 2 || planinfo.plantype == 4">
                            <el-radio-group v-model="planinfo.rerent">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="2">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="续租方案" prop="rerentprice" v-if="planinfo.rerent == 1" :required ="planinfo.rerent == 1?true:false" :rules="{message:'必须填写续租比例(数字)',trigger:'blur'}">
                            到期买断价 X <el-input style="width:215px;" v-model="planinfo.rerentprice"></el-input>
                            <span style="color:red">（*填写0.8-1.6范围内的数字）</span>
                        </el-form-item>
                        
                        <el-form-item  label="规格列表" >
                            <el-table :data="planinfo.tabledata" border style="width: 90%; margin: 1em 0;">
                                <el-table-column v-for="column in planinfo.tablecolumn" :key="column.label" :label="column.label" :prop="column.prop">
                                    <template #default='scope'>
                                        <span v-if='scope.column.property.indexOf("k_")!= -1'>{{ scope.row[column.prop]}}</span>
                                        <span v-else>
                                            <el-input v-model="scope.row[column.prop]" @change="CheckRentPrice(scope.row[column.prop],scope.$index,column.prop)" size="mini"></el-input>
                                        </span>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-button @click="CalRent" type="primary">总租金预览</el-button>
                        </el-form-item>
                        <el-form-item label="保障服务">
                            
                            <el-checkbox-group v-model="planinfo.guarante" class="gurant-group" @change="ResetGroup">
                                <div v-if="planinfo.guarantinfo.length > 0">
                                    <div v-for="guarante in guarantes" :key="guarante">
                                        <el-checkbox :label="guarante.ID">{{guarante.GuarantTitle}}</el-checkbox>
                                        <el-card v-if="planinfo.guarante.indexOf(guarante.ID)!=-1" >
                                            <div class="gurantopt">
                                                <div class="guarantlabel">
                                                    <span>价格(元)：</span>
                                                    <el-input v-model="guarante.Price" size="mini" @change="SetGuarantInfo(guarante.Price)" ></el-input>
                                                </div>
                                                <div   class="guarantlabel">
                                                <span>是否必选：</span>
                                                <el-radio-group v-model="guarante.MustBuy" @change="Check">
                                                    <el-radio :label="1">是</el-radio>
                                                    <el-radio :label="2">否</el-radio>
                                                </el-radio-group>
                                                </div>
                                                <div  class="guarantlabel">
                                                <span>是否默认勾选：</span>
                                                <el-radio-group v-model="guarante.DefaultBuy" @change="Check">
                                                    <el-radio :label="1">是</el-radio>
                                                    <el-radio :label="2">否</el-radio>
                                                </el-radio-group>
                                                </div>
                                            </div>
                                        </el-card>
                                    </div>
                                </div>
                                <div v-else>
                                    <div v-for="guarante in guarantes" :key="guarante">
                                        <el-checkbox :label="guarante.ID">{{guarante.GuarantTitle}} </el-checkbox>
                                        <el-card v-if="planinfo.guarante.indexOf(guarante.ID)!=-1">
                                            <div class="gurantopt">
                                                <div class="guarantlabel">
                                                    <span>价格(元)：</span>
                                                    <el-input v-model="guarante.Price" size="mini" @change="SetGuarantInfo(guarante.Price)" ></el-input>
                                                </div>
                                                <div   class="guarantlabel">
                                                <span>是否必选：</span>
                                                <el-radio-group v-model="guarante.MustBuy">
                                                    <el-radio :label="1">是</el-radio>
                                                    <el-radio :label="2">否</el-radio>
                                                </el-radio-group>
                                                </div>
                                                <div  class="guarantlabel">
                                                <span>是否默认勾选：</span>
                                                <el-radio-group v-model="guarante.DefaultBuy">
                                                    <el-radio :label="1">是</el-radio>
                                                    <el-radio :label="2">否</el-radio>
                                                </el-radio-group>
                                                </div>
                                            </div>
                                        </el-card>
                                        
                                    </div>
                                </div>
                                </el-checkbox-group>
                            
                        </el-form-item>
                        <div class="bottom">
                            <el-button type="success" @click="Sumbitform('planinfo')">确定</el-button>
                            <el-button type="info" @click="Back">返回</el-button>
                        </div>
                    </el-form>
                </div>
            </div>
        </el-card>
        <el-dialog v-model="calrent" width="80%" title="总租金概览">
            <el-table :data="totalrent" border style="width: 100%; margin: 1em 0;" size="mini">
                <el-table-column v-for="column in planinfo.tablecolumn" :key="column.label" :label="column.label" :prop="column.prop">
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>
<style scoped>
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.card-title {
    font-weight: bold;
    color: #4299e6;
}
.bottom{
    margin-top: 13px;
    padding-top: 13px;
    line-height: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: #ebeef5 1px solid;
}
.gurant-group{
    font-size: 12px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.guarantlabel{
    width: 150px;
    margin-right:10px;
}
.gurantopt{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
</style>
<script>
import constant from "@/constant"
import { ElMessage } from 'element-plus'
export default {
    data(){
        return {
            
            calrent:false,
            activeName:'',
            category:'',
            brand:'',
            model:'',
            product:'',
            //tabledata:[],
            rank:[],
            guarantes:[],
            key:[],
            planinfo:{
                updatecomplete:true,
                id:'',
                guarante:[],
                guarantinfo:[],
                rerentprice:1,
                plantype:'',
                category:'',
                brand:'',
                model:'',
                value:'',
                product:'',
                title:'',
                params:[],
                margin:'',
                deposit:'',
                renttype: '',
                rankstart:'1',
                rankend:'8',
                showtable:false,
                tablecolumn:[],//table列label/prop数组
                tabledata:[],
                buyout:1,
                rerent:'',
                buyoutprice:0,
                rprice:0,
                price:0,
            }
        }
    },
    methods:{
        CalRent(){
            this.totalrent = [];
            for(let i = 0;i<this.planinfo.tabledata.length;i++){
                let cur_row = this.planinfo.tabledata[i];
                let obj = Object();
                for(let key in cur_row){
                    if(key == "d1")
                        obj[key] = cur_row[key]*1;
                    else if(key == "d3")
                        obj[key] = cur_row[key]*3;
                    else if(key == "d7")
                        obj[key] = cur_row[key]*7;
                    else if(key == "d15")
                        obj[key] = cur_row[key]*15;
                    else if(key == "m1"){
                        if(this.planinfo.plantype!=2)
                            obj[key] = cur_row[key]*30;
                        else
                            obj[key] = cur_row[key]*1;
                    }
                        
                    else if(key == "m3"){
                        if(this.planinfo.plantype!=2)
                            obj[key] = cur_row[key]*90;
                        else
                            obj[key] = cur_row[key]*3;
                    }
                        
                    else if(key == "m6"){
                        if(this.planinfo.plantype!=2)
                            obj[key] = cur_row[key]*180;
                        else
                            obj[key] = cur_row[key]*6;
                    }
                        
                    else if(key == "y1"){
                        if(this.planinfo.plantype!=2)
                            obj[key] = cur_row[key]*365;
                        else
                            obj[key] = cur_row[key]*12;
                    }
                        
                    else if(key == "y2"){
                        if(this.planinfo.plantype!=2)
                            obj[key] = cur_row[key]*730;
                        else
                           obj[key] = cur_row[key]*24; 
                    }
                        
                    else if(key == "y3"){
                        if(this.planinfo.plantype!=2)
                            obj[key] = cur_row[key]*1059;
                        else
                           obj[key] = cur_row[key]*36;
                    }
                        
                    else
                        obj[key] = cur_row[key];
                }
                this.totalrent.push(obj);
            }
            console.log(this.totalrent);
            this.calrent = true;
        },
        ResetType(){
            if(this.planinfo.renttype == 3 || this.planinfo.renttype == 4){
                if(this.planinfo.rankstart*1 < 5)
                    this.planinfo.rankstart = '5';
            }
            this.AlterTable();
            
        },
        CheckRentPrice(val,index,prop){
            console.log(val);
            console.log(index);
            console.log(prop);
            let cur_row = this.planinfo.tabledata[index];
            
            
            if(prop == 'd3'){
                if(val*1 > cur_row.d1*1){
                    this.$message.error("后一阶的租金不能高于前一阶")
                    this.planinfo.tabledata[index][prop] = "";
                }
                
            }
            if(prop == 'd7'){
                if(val*1 > cur_row.d3*1 || val > cur_row.d1*1){
                    this.$message.error("后一阶的租金不能高于前一阶")
                    this.planinfo.tabledata[index][prop] = "";
                }
                
            }
            if(prop == 'd15'){
                if(val*1 > cur_row.d7*1  || val > cur_row.d1*1 || val > cur_row.d3*1){
                    this.$message.error("后一阶的租金不能高于前一阶")
                    this.planinfo.tabledata[index][prop] = "";
                }
                
            }
            if(prop == 'm1'){
                if(val*1 > cur_row.d15*1 || val*1 > cur_row.d7*1  || val*1 > cur_row.d1*1 || val*1 > cur_row.d3*1){
                    this.$message.error("后一阶的租金不能高于前一阶")
                    this.planinfo.tabledata[index][prop] = "";
                }
                
            }
            if(prop == 'm3'){
                if(val*1 > cur_row.m1*1 || val*1 > cur_row.d15*1 || val*1 > cur_row.d7*1  || val*1 > cur_row.d1*1 || val*1 > cur_row.d3*1){
                    this.$message.error("后一阶的租金不能高于前一阶")
                    this.planinfo.tabledata[index][prop] = "";
                }
                
            }
            if(prop == 'm6'){
                if(val*1 > cur_row.m3*1 || val*1 > cur_row.m1*1 || val*1 > cur_row.d15*1 || val*1 > cur_row.d7*1 || val*1 > cur_row.d1*1 || val*1 > cur_row.d3*1){
                    this.$message.error("后一阶的租金不能高于前一阶")
                    this.planinfo.tabledata[index][prop] = "";
                }
                
            }
            if(prop == 'y1'){
                if(val*1 > cur_row.m6*1 || val*1 > cur_row.m3*1 || val*1 > cur_row.m1*1 || val*1 > cur_row.d15*1 || val*1 > cur_row.d7*1 || val*1 > cur_row.d1*1 || val*1 > cur_row.d3*1){
                    this.$message.error("后一阶的租金不能高于前一阶")
                    this.planinfo.tabledata[index][prop] = "";
                }
                
            }
            if(prop == 'y2'){
                if(val*1 > cur_row.y1*1 || val*1 > cur_row.m6*1 || val*1 > cur_row.m3*1 || val*1 > cur_row.m1*1 || val*1 > cur_row.d15*1 || val*1 > cur_row.d7*1  || val*1 > cur_row.d1*1 || val*1 > cur_row.d3*1){
                    this.$message.error("后一阶的租金不能高于前一阶")
                    this.planinfo.tabledata[index][prop] = "";
                }
                
            }
            if(prop == 'y3'){
                if(val*1 > cur_row.y2*1 || val*1 > cur_row.y1*1 || val*1 > cur_row.m6*1 || val*1 > cur_row.m3*1 || val*1 > cur_row.m1*1 || val*1 > cur_row.d15 || val*1 > cur_row.d7*1  || val*1 > cur_row.d1*1 || val*1 > cur_row.d3*1){
                    this.$message.error("后一阶的租金不能高于前一阶")
                    this.planinfo.tabledata[index][prop] = "";
                }
                
            }
                
            
        },
        CheckPrice(val){
            console.log(val);
            if(val){
                if(isNaN(val)){
                    this.$message.error("金额必须是数字");
                }
            }
        },
        Back(){
            this.$router.push({path:'/planlist',query:{id:this.activeName}});
        },
        AlterTable(){
            console.log(this.planinfo.params);
            let count = this.planinfo.params.length;
            let newcolumn = [];
            for(let j = 0;j<=count;j++){
                console.log('j---'+j);
                newcolumn.push(this.planinfo.tablecolumn[j]);
            }
            console.log(newcolumn);
            
            for(var key in this.rank){
                console.log(key);
                var cur_rank_obj = this.rank[key];
                console.log(cur_rank_obj);
                if(key*1 >= (this.planinfo.rankstart)*1 && (this.planinfo.rankend)*1 >= key*1){
                    var rank_obj = Object();
                    if(this.planinfo.renttype == 3 || this.planinfo.renttype == 4)
                            rank_obj.label = cur_rank_obj.label + "（元/月）";
                        else
                            rank_obj.label = cur_rank_obj.label + "（元/天）";
                    rank_obj.prop = cur_rank_obj.prop
                    rank_obj.val = cur_rank_obj.val;
                    newcolumn.push(rank_obj);
                }
            }

            this.planinfo.tablecolumn = newcolumn;
        },
        SetGurantGroup(){

            console.log(this.planinfo.guarante);
            this.planinfo.guarantinfo = [];
            for(let i = 0;i<this.planinfo.guarante.length;i++){
                for(let j = 0;j<this.guarantes.length;j++){
                    if(this.guarantes[j].ID == this.planinfo.guarante[i]){
                        this.planinfo.guarantinfo.push(this.guarantes[j]);
                    }
                }
            }
            console.log(this.planinfo.guarantinfo);
        },
        ResetGroup(){
            //console.log('reset');
            //console.log(this.planinfo.guarante);
            //console.log(this.planinfo.guarantinfo);
            var newinfo = [];
            for(let i = 0;i<this.planinfo.guarante.length;i++){
                for(let j = 0;j<this.guarantes.length;j++){
                    if(this.guarantes[j].ID == this.planinfo.guarante[i]){
                        newinfo.push(this.guarantes[j]);
                    }
                }
            }
            console.log(newinfo);
            this.planinfo.guarantinfo = newinfo;
        },
        SetGuarantInfo(val){
            console.log(this.planinfo.guarante);
            console.log(this.planinfo.guarantinfo);
            if(!typeof val === 'number' || isNaN(val)){
                    ElMessage.error({
                        type:'error',
                        message:'保障价格必须是数字'
                    });
                    return false;
                }
        },
        Sumbitform(formName){
            this.$refs[formName].validate((valid)=>{
                if(valid){
                    if(!typeof this.planinfo.buyoutprice === 'number' || isNaN(this.planinfo.buyoutprice)){
                        ElMessage.error({
                            type:'error',
                            message:'请填写正确的签约金额(数字)'
                        });
                        return false;
                    }
                    
                    if(this.planinfo.rerent == 1 && this.planinfo.rerentprice && (this.planinfo.rerentprice < 0.8 || this.planinfo.rerentprice > 1.6)){
                        ElMessage.error({
                            type:'error',
                            message:'续租方案金额范围必须在0.8到1.6之间'
                        });
                        return false;
                    }
                    if(this.planinfo.tabledata.length <= 0){
                        ElMessage.error({
                            type:'error',
                            message:'必须填写价格列表'
                        });
                        return false;
                    }
                    console.log(this.planinfo);
                    
                    
                    this.axios.post(constant.save_edit_plan_url,this.planinfo,{
                        headers:{'Content-Type': 'application/x-www-form-urlencoded'}
                    }).then((response)=>{
                        console.log(response.data);
                        if(response.data == 'OK'){
                            ElMessage.success({
                                type:'success',
                                message:'保存成功'
                            });
                            return false;
                        }else{
                            ElMessage.error({
                                type:'error',
                                message:'保存失败'
                            });
                            return false;
                        }
                    });
                }else{
                    return false
                }
            });
        },
        Check(){
            console.log(this.planinfo.guarantinfo);
        }
    },
    created:function(){
        let id = this.$route.query.id;
        console.log(id);
        this.axios.get(constant.edit_plan_url,{
            headers:{
                'Content-Type': 'application/json'
            },
            params :{
                id : id
            }
        }).then((response)=>{
            console.log(response.data);
            if(response.data.msg == 'OK'){
                this.activeName = response.data.planinfo.Plantype;
                this.planinfo.id = response.data.planinfo.ID;
                this.planinfo.pdtid = response.data.planinfo.PdtID;
                this.category = response.data.planinfo.Category;
                this.brand = response.data.planinfo.Brand;
                this.model = response.data.planinfo.Model;
                this.product = response.data.planinfo.PdtName;
                this.tabledata = response.data.plandetail;
                this.planinfo.title = response.data.planinfo.PlanTitle;
                this.planinfo.plantype = response.data.planinfo.Plantype;
                this.planinfo.renttype = response.data.planinfo.RentType*1;
                this.planinfo.margin = response.data.planinfo.Margin;
                this.planinfo.deposit = response.data.planinfo.Deposit;
                this.planinfo.rprice = response.data.planinfo.RPrice;
                this.planinfo.price = response.data.planinfo.Price;
                this.planinfo.buyout = response.data.planinfo.Buyout*1;
                this.planinfo.buyoutprice = response.data.planinfo.BuyoutPrice;
                this.planinfo.rerent = response.data.planinfo.Rerent*1;
                this.planinfo.rerentprice = response.data.planinfo.RerentPrice;
                this.planinfo.rankstart = response.data.planinfo.RankStart;
                this.planinfo.rankend = response.data.planinfo.RankEnd;
                this.planinfo.tabledata = response.data.plandetail;
                this.planinfo.tablecolumn = response.data.planinfo.TableColumn;
                if(response.data.planinfo.guarante)
                    this.planinfo.guarante = response.data.planinfo.guarante;
                if(response.data.planinfo.guarantinfo)
                    this.planinfo.guarantinfo = response.data.planinfo.guarantinfo;
                console.log("info");
                console.log(this.planinfo.guarante);
                console.log(this.planinfo.guarantinfo);
                this.planinfo.params = response.data.planinfo.Params;
                this.guarantes = response.data.guarantes;
                this.axios.get(constant.get_guarant_url,{
                    headers:{
                        'Content-Type': 'application/json'
                    },
                    params:{
                        status:1
                    }
                }).then((response)=>{
                    console.log("this.guarantes");
                    console.log(response.data);
                    this.guarantes = response.data;
                    if(this.planinfo.guarantinfo){
                        for(let i = 0;i<this.guarantes.length;i++){
                            let cur_id = this.guarantes[i]['ID'];
                            for(let j = 0;j<this.planinfo.guarantinfo.length;j++){
                                if(cur_id == this.planinfo.guarantinfo[j]['GuarantID']){
                                    this.guarantes[i].Price = this.planinfo.guarantinfo[j]['Price'];
                                    this.guarantes[i].MustBuy = this.planinfo.guarantinfo[j]['MustBuy'];
                                    this.guarantes[i].DefaultBuy = this.planinfo.guarantinfo[j]['DefaultBuy'];
                                }
                            }
                            
                        }
                    }
                    
                })
            }
        });

        

        

        this.axios.get(constant.get_rank_url,{
            headers:{
                'Content-Type': 'application/json'
            }
        }).then((response)=>{
            
            console.log(response.data);
            this.rank = response.data;
        });
    }
}
</script>